@import './variables';

@import './custom/reset';
@import '~bootstrap/scss/bootstrap';

@import '~@fortawesome/fontawesome-svg-core/styles.css';

@import '~@leihs/calendar/dist/styles.css'; // main css file
@import '~@leihs/calendar/dist/theme/default.css'; // theme css file

@import './custom/globals';
@import './custom/typography';
@import './custom/pages';
@import './custom/bootstrap-extensions';
@import './custom/app-fixes';

// styling of react components
@import './custom/navbar';
@import './custom/datepicker';

// pure CSS components
@import './custom/hr-text';
