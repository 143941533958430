// datepicker custom control
.custom-datepicker {
  .custom-datepicker-textinput,
  .custom-datepicker-calendar-container {
    // NOTE: from bootstrap's .form-control
    transition:
      border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;
  }
  & .custom-control-focussed {
    .custom-datepicker-textinput,
    .custom-datepicker-calendar-container {
      border-color: $input-focus-border-color !important;
    }
    .custom-datepicker-textinput {
      box-shadow: none !important;
    }
  }
  // calendar
  .rdrMonthAndYearWrapper {
    padding-top: 0;
  }
}

@mixin custom-datepicker-form-validation-state($state, $color, $icon) {
  .custom-datepicker {
    @include custom-control-form-validation-state-selector($state) {
      & .#{$state}-feedback,
      & .#{$state}-tooltip {
        display: block;
      }
    }
  }
  .custom-datepicker-calendar-container {
    @include custom-control-form-validation-state-selector($state) {
      border-color: $color;

      & .custom-control-focussed {
        .custom-datepicker-textinput,
        .custom-datepicker-calendar-container {
          border-color: $color;
          box-shadow: 0 0 0 $input-focus-width rgba($color, 0.25);
        }
      }
    }
  }
}
@each $state, $data in $form-validation-states {
  @include custom-datepicker-form-validation-state($state, map-get($data, color), map-get($data, icon));
}
